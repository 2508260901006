<template>
    <div class="body_container">
    <div class="publication-body-element">
        <div class="publication-element">
            <div class="publication-title">
                <h2 class="publication-title-text">{{$route.query.name}}</h2>
            </div>  
            <div class="publication-main" v-for="p in partnerPubs" :key="p.title">      
                <ul>
                    <li>
                        <p class="publication-topic"></p>
                        <a :href=p.uri><p class="publication-titles">{{p.title}}</p></a>
                        <span class="publication-creator" v-if="type(p.creator)"><a :href=linkPerson(p.creator)>{{p.creator}}</a></span>
                        <span class="publication-creator" v-else >
                            <span v-for="namec in p.creator" :key="namec"><a :href=linkPerson(namec)>{{namec}}</a>; </span>
                        </span>
                        <span class="publication-creator">-- {{p.date}}</span> <!-- {{p.topic}} -->
                         </li>
                </ul>
            </div>
        </div>

    </div>

  </div>
    
  
</template>

<script>
import axios from 'axios';
import { get } from '../utils/request'

export default {
    data(){
        return {
            msg:'',
            partnerPubs:{}
        }
    },
    methods:{
        getMessage() {
            // console.log(this.$route.query.name)
            // const path = `${this.GLOBAL.BASE_URL}datasetDetails/${this.$route.query.name}`
            // // console.log(path)
            // axios.get(path)
            get(`datasetDetails/${this.$route.query.name}`, null, false)
            .then((res) => {
                // console.log(res.data);
                this.partnerPubs = res.data;
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        type(creator){
            if(Array.isArray(creator)){
                return false;
            }else{
                return true;
            }
        },
            linkPerson(name){
                name=name.replace(" ","+")
                name=name.replace(",", "%2C")
                name="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q="+name+"&btnG="
                return name
            }

    },
    mounted(){
            
            console.log(this.$route)
        },
    created() {
        this.getMessage();
    }

}
    
    


</script>

<style>

</style>