<template>
  <div>
  <div class="about_container">
    <h2 class="about_subtitle">ENGAGE.EU Community Platform</h2>
    <img class="communityimg" src="../assets/community.png">
    <p class="about_text">ENGAGE.EU aims at facilitating ENGAGE.EU networking for academic research. 
    The community platform helps researchers to find collaboration opportunities more easily, search for relevant information by research themes, and create or participate in events. 
    The platform also provide features such as newsletters to help users stay up-to-date on the latest activities and build their network. 
    Since the website went live, they have been continuously gaining a large number of new users. 
    If you have interest to build your research network, don't hesitate to explore the platform.</p>
    
  <a class="about_link" href="https://www.research-community-engage.eu/" target="_blank">Check out ENGAGE.EU Community Platform here</a>
</div>
  </div>
</template>
<script>

    
export default {
    components:{
    },

}
</script>

<style>
.communityimg{
    width : 800px;
}
</style>