<template>

<div class="body_container">
<div class="publication-body-element">

    <div class="main-search"> 

        <!-- simple search -->

        <div v-if="!isAdvance">
            <P class="ad_search_advanced" @click="toAdvancedSearch()"> Advanced search</P>
            <form class="main-research" v-on:submit.prevent="submitForm" >
                
                
                <div class="main-type-div">
                    <select class="main-type" id="searchType" v-model="formd.searchType" required> 
                        <!-- <option  value="organization">organisation</option> -->
                        <option value="all">All</option>
                        <option value="title">Title/Keywords</option>
                        <option value="author">Author</option>
                    </select>
                </div>
                <div>
                    <input type="text" id="searchText" name="main-research-text" class="main-research-text" :placeholder="placeholder_input(formd.searchType)" v-model="formd.searchText" required> 
                </div>
                <div class="main-research-sent-div">
                    <button class="main-research-send"><img class="main-icon-magnifier" src="../assets/magnifier-icon.png"></button>
                </div> 
            </form>
        </div>
        
        <!-- advanced search -->

        <div v-if="isAdvance">
            <form  v-on:submit.prevent="submitAdvancedForm" >
                <div class="advanced_research_top">
                    <P class="ad_search_simple" @click="toSimpleSearch()">Back to Simple search</P>
                
                </div>
                
                <div class="advanced_research">
                    <p class="search_subtitle">Author</p><input class="ad_input" id="ad_input_author" type="text" v-model="advancedSearch_author">
                    <div class="ad_logique_and_author"><p class="ad_and_author">and</p></div> 
                    <p class="search_subtitle">Keywords</p>

                <div class="ad_col ad_content">
                    <div class="ad_col" v-for="(bloc,index) in advancedSearch_text" :key="index"> 
                        <div class="ad_row ad_center">  
                            <p  class="parenthese para_left">(</p> 
                            
                                <div class="ad_row ad_search_word">
                                    <div class="ad_row ad_espace" v-for="(words, indexT) in bloc" :key="indexT"> 
                                        <input class="ad_input" type="text" v-model="bloc[indexT]">
                                        <img class="ad_delete_img" v-if="showDelete" alt="delete" @click="deleteone(index, indexT)" src="../assets/croix.png">
                                        <p class="ad_logique" v-if="concatOr(bloc, indexT)">or</p>    
                                    </div> 
                                    <p @click="addOr(index)" class="ad_click ad_click_or">or</p>
                                    <p  class="parenthese">)</p> 
                                </div>     
                            
                        </div> 
                        <p class="ad_logique_and" v-if="concatAnd(index)">and</p> 
                    </div>
                    <p  @click="addAnd()" class="ad_click">and</p>
                </div>
                </div>
                <div class="advanced_research_button">
                    <button class="ad_button">Advanced Search</button>
                </div> 
            </form>
            <div class="advanced_research_delete">
                <button class="ad_button" @click="deleteWords()">DELETE ALL</button>
            </div> 
        </div>
    </div>


        <div class="result_body">

            <!-- selector bar: "publications" or "projects" -->
            <table class="project_table"> 
                <tr> 
                    <td @click="showPubs()"><span class="project_click" :class="{'project_onglet':displayPP}">Publications</span></td>
                    <td v-if="haveproject" @click="showProjects()"><span class="project_click" :class="{'project_onglet':!displayPP}">Projects</span></td>
                </tr>
            </table>

            <LoadingMessage 
                :messageDisplay=messageDisplay 
                :messageStatus=messageStatus 
            />

            
            <div v-if="!messageDisplay">

                <!-- publications -->

                <div v-if="show" class="publication-element" >
                    <div class="publication-main" v-for="p in dataShow" :key="p.title">      
                        <ul>
                            <li >
                                <Pub
                                :p = p />
                            </li>
                        </ul>
                    </div>
                    
                </div>

                <!-- projects -->

                <div v-else>
                    <div > 
                        <div v-for="(project, index) in dataShow" :key="index">
                        <div class="publication-element">
                            <Project :project="project"/>  
                        </div>
                    </div>
                    </div>   
                </div>

            </div>
        </div>

        <pagination
            :data = dataPage
            :display=display
            @returnData="showPage" 
        />


    </div>
    
</div>
</template>

<script>

// import axios from 'axios';
import Project from '../components/Project'
import Pagination from '../components/pagination'
import { post } from '../utils/request'
import LoadingMessage from '../components/LoadingMessage'
import store from '../store';
import Pub from '../components/Pub';

    export default{
        components:{
            Project,
            Pagination,
            LoadingMessage,
            Pub
        },
        name:"Result",
        
        propos:{
            ref: {
                type: String,
                default: "Result"
            }
        },
        data(){
            return{
                haveproject:true,
                formd:{
                    searchType:'title',
                    searchText:''
                },
                dataPage: [],
                pubs:{},
                projects:[],
                partner:[],
                total: 0,     // nb pubs total
                display: 10,   // nb max in one page
                dataShow:[],  //data of this page
                sortSdgEtat: false,
                isAdvance : false,
                advancedSearch_lang : "English",
                advancedSearch_text:[[""]],
                advancedSearch_author:"",
                showDelete: false,
                displayPP: true,
                show: true,      // show publications or projects
                // result: 0,

                messageDisplay: false,
                messageStatus: "loading",
            }
        },
        methods:{
            // get result of simple search 
            submitForm(){
                this.messageDisplay = true;
                this.messageStatus = "loading";
                post('search', this.formd, {headers:{"Content-Type" : "application/json"}}, true)
                .then((res) => {
                    if(res.status!=200){
                        this.messageStatus = "network error";
                    }else{
                        this.pubs=res.data.pubs
                        this.dataPage = this.pubs
                        if('project' in res.data){
                            this.projects=res.data.project
                            this.haveproject=true
                        }else{
                            this.haveproject=false
                        }
                        this.showPubs()
                    }  
                })
                .catch((error)=>{
                    this.messageStatus = "service error";
                    console.log(error)
                })
                
            
            },

            // get result of advanced search 
            submitAdvancedForm(){
                // const path = `${this.GLOBAL.BASE_URL}advancesearch`
                this.messageDisplay = true;
                this.messageStatus = "loading";
                var postdic={'advancedSearch_text':this.advancedSearch_text,'advancedSearch_lang':this.advancedSearch_lang,'advancedSearch_author':this.advancedSearch_author}
                // axios.post(path, postdic, {headers:{"Content-Type" : "application/json"}})
                post("advancesearch", postdic, {headers:{"Content-Type" : "application/json"}}, true)
                .then((res) => {
                    // console.log(res)
                    if(res.status!=200){
                        this.messageStatus = "network error";
                    }else{
                        this.pubs=res.data
                        this.dataPage = this.pubs
                        this.haveproject = false
                    }
                   
                })
                .catch((error)=>{
                    this.messageStatus = "service error";
                    console.log(error)
                })
                
            
            },

            // get data for a certain page number
            showPage(returnData){
                if(returnData.length == 0){
                    this.messageDisplay = true; 
                    setTimeout(()=>{
                        this.$set(this, "messageStatus", "no result")
                    }, 10000)
                    
                }else{
                    this.$set(this, "dataShow", returnData)
                    this.messageDisplay = false;  
                    document.documentElement.scrollTop = 0;

                }
            },

            // type of data "creator"
            type(creator){
                if(Array.isArray(creator)){
                    return false;
                }else{
                    return true;
                }
            },

            // link for searching one author at google scholar
            linkPerson(name){
            return store.linkPerson(name);
            },
            getRouterData() {
                this.isAdvance = this.$route.query.isAdvance
            },

            // generate the search list for advanced search and graph interface
            concatOr(words, indexT){
                if(indexT+1<words.length){
                    return true
                }else{
                    return false
                }
            },
            concatAnd(index){
                if(index+1<this.advancedSearch_text.length){
                    return true
                }else{
                    return false
                }
            },
            addOr(index){
                this.advancedSearch_text[index].push("");
            },
            addAnd(){
                this.advancedSearch_text.push([""]);
            },
            deleteWords(){
                this.advancedSearch_text=[[""]];
            },
            deleteone(index, indexT){
                // console.log(this.advancedSearch_text[0].length)
                if(this.advancedSearch_text.length>1 | this.advancedSearch_text[0].length>1){
                    this.advancedSearch_text[index].splice(indexT, 1);
                    if(this.advancedSearch_text[index].length == 0){
                        this.advancedSearch_text.splice(index, 1);
                    }
                }

            },

            // switch search mode 
            toSimpleSearch(){
                this.isAdvance = false;
            },
            toAdvancedSearch(){
                this.isAdvance = true;
            },

            // generate graph interface for simple search
            placeholder_input(text){
                if(text == "all"){
                    return "title, keywords, author..."
                }else if(text == 'title'){
                    return "title, keywords..."
                }else if(text == "author"){
                    return "author..."
                }
            },

            // generate selector banner
            showPubs(){
                this.show=true     // content
                this.displayPP=true  // banner style
                this.dataPage = this.pubs
            },
            showProjects(){
                this.show=false     // content
                this.displayPP=false  // banner style
                this.dataPage = this.projects
            },
            getaffichename(un){
                return store.getNameAffiche(un);
            }
        },
        watch:{
            advancedSearch_text: {
                handler(){
                    if(this.advancedSearch_text.length==1 && this.advancedSearch_text[0].length==1){
                        this.showDelete = false
                    }else{
                        this.showDelete = true
                    }
                },
                deep: true
            }
            
        },
        created(){
            // get partners information if not 
            if(store.getSharedVariable().length==0){
                store.setSharedVariable();
            } 

            // get search information
            if(!this.$route.params.searchText=="" && Object.keys(this.$route.params).length != 0){
                this.formd=this.$route.params
                
            }
           
            // sent http request to get right result
            this.submitForm();
            this.getRouterData();
        },
        }
             
        

    

</script>

<style>

</style>