<template>
    <div class="resources_one_filter"> 
        <div class="filter_title">
            <p class="resources_filter_type">{{keyFilter}}</p>
            <div class="button_filter">
                <div @click="selectAll()" class="resources_clear">Select all</div>
                <div @click="clearFilter()" class="resources_clear">Reset</div>
            </div>
        </div>
        <div v-for="un in sortedList" :key="un">
            <input :name="keyFilter" type="checkbox" :value="un" v-model="search"> 
            <span v-if="keyFilter  == 'university' "> {{ getAffiche(un) }} - {{ getFull(un) }} </span> 
            <span v-else> {{ show(un) }} </span>
        </div>
    </div>
</template>

<script>
import store from '../store';

export default{
    name:"FilterEl",
    props: {
        list: {
            type: Array
        },
        keyFilter:{
            type:String
        },
    },
    data(){
        return {
            search:[],
            partner:[],
            // sortedList: []
        }
    },
    methods:{
        show(el){
            if(el == null){
                return "Undefined"
            }if(el == ""){
                return "Undefined"
            }if(this.keyFilter == "university"){
                return this.universityName(el)
            }
            else{
                return el
            }
        },
        universityName(el){
            for(var i=0;i<this.partner.length;i++){
                if (this.partner[i].shortName == el){
                    return this.partner[i].Affiche + " - " + this.partner[i];
                }
            }
        },
        clearFilter(){
            this.search = []   
        },
        selectAll(){
            this.search = this.list
        },
        getAffiche(un){
            return store.getNameAffiche(un)
        },
        getFull(un){
            return store.getFullName(un)
        },
    },
    watch:{
        search: {
            handler(){
                this.$emit("filterChange", this.keyFilter, this.search)
            },
        }
        
    },
    created(){
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        }  
    },
    computed:{
        sortedList(){
            let list = this.list

            let str = "other"
            let nonEmpty = list.filter(item => item && item !== "" && item.toUpperCase().indexOf(str.toUpperCase())==-1)
            let difference = list.filter(item => !nonEmpty.includes(item));

            if(this.keyFilter == "topic"){
                nonEmpty.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
            }
            if(this.keyFilter == "university"){
                nonEmpty= store.getUniversitySorted(list);
                // console.log(store.getUniversitySorted(list))
            }
            else{
                nonEmpty.sort()
            }

            list = [...nonEmpty, ...difference]
            // this.$set(this, "sortedList", list)
            return list
        }
    }
}
</script>
<style>
.resources_filter_type{
    text-transform: capitalize;
    margin: 0%;
    margin-top: 0.5em;
}
.button_filter{
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    width: 8em;
    margin-top: 0.5em;

}
.filter_title{
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5em;
    margin-bottom: 1em;
}
</style>