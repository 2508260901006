import axios from 'axios'
import {showFullScreenLoading, hideFullScreenLoading} from "./loading"

// variables 
// create an AXIOS instance
const instance = axios.create({
    // baseURL: 'http://127.0.0.1:5000/',
    baseURL: 'https://engage-proto.ut-capitole.fr/app/',  ////ENV PROD
    timeout: 10000
});
var viewName = "";
var loadingCount = 0;

// functions 
// show the loading icon
function addLoading(){
    loadingCount++;
    if(viewName!=""){
        showFullScreenLoading(viewName)
    }
}
// close loaing icon
function isCloseLoading() {
    loadingCount--;
    if (loadingCount == 0) {
        if(viewName!=""){
            hideFullScreenLoading();
        } 
   }
}

// request interceptor
instance.interceptors.request.use(config=>{
    addLoading();
    return config;
},err=>{
    isCloseLoading();
    loadingCount = 0;
    return Promise.reject(err);
});
// response interceptor
instance.interceptors.response.use(config=>{
    isCloseLoading();
    return config;
},err=>{
    isCloseLoading();
    loadingCount = 0;
    return Promise.reject(err);
});

// send request to backend by http get
export function get(endpoint, param, loading){
    if(loading==true){
        viewName = ".publications_loading"
    }else{
        viewName = ""
    }
    return instance.get(endpoint, param);
}
// send request to backend by http post
export function post(endpoint, data, headers, loading){
    if(loading==true){
        viewName = ".publications_loading"
    }else{
        viewName = ""
    }
    
    return instance.post(endpoint, data, headers);
}


