<template>
    <div>
      <p class="publication-topic"></p>
      <p class="col_publication-titles" v-if="collab">{{ p.title }}</p>
      <a :href="p.uri" target="_blank" v-else>
        <p class="publication-titles">{{ p.title }}</p>
      </a>
      <span class="publication-creator" v-if="isSingleCreator">
        <a v-if="creatorLinks[p.creator]" target="_blank" :href="creatorLinks[p.creator].link" :style="{ color: creatorLinks[p.creator].isValid ? '#ff4f00' : ''}">
          {{ p.creator }}
          <img v-if="creatorLinks[p.creator].isValid" src="../assets/engagelogo.jpg" class="small-logo" />
        </a>
      </span>
      <span v-else>
        <span class="publication-creator" v-for="namec in p.creator" :key="namec">
          <a v-if="creatorLinks[namec]" target="_blank" :href="creatorLinks[namec].link" :style="{ color: creatorLinks[namec].isValid ? '#ff4f00' : ''}">
            {{ namec }}
            <img v-if="creatorLinks[namec].isValid" src="../assets/engagelogo.jpg" class="small-logo" />
          </a>
          ;
        </span>
      </span>
      <span class="publication-creator">-- {{ p.date }}</span>
      <span v-if="!topic && !isSingleTopic">
        <span class="publication-creator" v-for="topicc in p.topic" :key="topicc">-- {{ topicc }};</span>
      </span>
      <span class="publication-creator" v-if="!partner">-- {{ getaffichename(p.partnerShortName) }}</span>
    </div>
  </template>
  
  <script>
  import { post } from '../utils/request'
  import store from '../store';
  
  export default {
    name: "Pub",
    props: {
      p: {
        type: Object,
        required: true
      },
      partner: {
        type: Boolean
      },
      topic: {
        type: Boolean
      },
      collab: {
        type: Boolean
      }
    },
    data() {
      return {
        creatorLinks: {}
      };
    },
    computed: {
      isSingleCreator() {
        return !Array.isArray(this.p.creator);
      },
      isSingleTopic() {
        return !Array.isArray(this.p.topic);
      },
      allCreatorsFetched() {
        if (this.isSingleCreator) {
          return !!this.creatorLinks[this.p.creator];
        } else {
          return this.p.creator.every(name => !!this.creatorLinks[name]);
        }
      }
    },
    methods: {
      async fetchCreatorLink(name) {
        const encodedName = encodeURIComponent(name.replace(/\s/g, "+").replace(/,/g, "%2C"));
        const path = `community_link`;
  
        try {
          const response = await post(path, { name: encodedName }, { headers: { "Content-Type": "application/json" } }, true);
  
          if (response.status === 200) {
            const data = response.data;
            this.$set(this.creatorLinks, name, { link: data.permalink, isValid: true });
          } else {
            throw new Error('Failed to fetch link');
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          this.$set(this.creatorLinks, name, {
            link: `https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=${name}&btnG=`,
            isValid: false
          });
        }
      },
      initializeCreatorLinks() {
        if (this.isSingleCreator) {
          this.fetchCreatorLink(this.p.creator);
        } else {
          this.p.creator.forEach(name => {
            this.fetchCreatorLink(name);
          });
        }
      },
      getaffichename(un){
                return store.getNameAffiche(un);
        }
    },
    created() {
      this.initializeCreatorLinks();
    }
  };
  </script>
  
  <style>
  .small-logo {
    display: initial;
    width: 1em;
  }
  </style>
  