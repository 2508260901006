<template>
    <div>
    
        <button @mouseover="mouseOver" @mouseleave="mouseLeave">TOPICS</button>
        
        <div  class="dropdown" @mouseover="mouseOver" @mouseleave="mouseLeave" :style="abc">
            <table>
            <tr><td><router-link to="/sdg?topic=socchange">Societal changes</router-link></td></tr>
            <tr><td><router-link to="/sdg?topic=sdg">Sustainable Development Goals</router-link></td></tr>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Topicmenu',
    data() {
        return {
            abc: "display: none;"}
        },
    methods: {
        // mouse in
        mouseOver() {
        this.abc = ""
        
        },
        // mouse out
        mouseLeave() {
        this.abc = "display: none;"
        
        }
    },
}
</script>

<style>

</style>