<template>
    <div class="publication-main" > 
        <img v-if="engageLogo(project.topic)" class="collaboration_logo_engage" src="../assets/ENGAGE EU Logo.jpg"> 
        <div class="resources_margin">
            <a target="_blank" @click="routerto(project.code)" class="resources_title project_title">{{ project.title}}</a>
            <p class="sous_titre">{{project.state}}</p>
        </div>
        <table class="resources_table"> 
            <tr v-if="project.startdate!=null"><td>Start date </td><td>{{project.startdate}}</td></tr>
            <tr v-if="project.enddate!=null"><td>End date </td><td>{{project.enddate}}</td></tr>
            <tr v-if="project.funders!=null"><td>Funder </td><td>{{project.funders}}</td></tr>
            <tr v-if="project.code!=null"><td>Project code </td><td>{{project.code}}</td></tr>
            <tr v-if="project.partnerShortName!=null"><td>Participants ENGAGE </td><td><div v-for="(uni, index) in project.partnerShortName" :key="index">{{ uni}}</div></td></tr>
        </table>
    </div>
</template>
<script>

export default ({
    name:"",
    props:{
        project:Object,
    },
    data(){
        return{

        }
    },
    methods:{
        engageLogo(topic){
            if(topic != null && topic>0){
                return true;
            }else{
                return false;
            }
        },
        // routerto(code){
        //     this.$router.push({
        //         name:"OneProject",
        //         query: {"code": code}
                
        //     })
        // },
        routerto(code) {
            // open a new window
            const newWindow = window.open('', '_blank');
            if (newWindow) {
                newWindow.location.href = this.$router.resolve({
                name: "OneProject",
                query: { "code": code }
                }).href;
            }
            }

    },
    created(){

    }
})
</script>
