<template>
    <div class="body_container">
    <div class="publication-body">
        <div class="update-date-div"> 
           <p class="update-date">updated on JAN 2023</p> 
        </div>

        <div class="publication-element" v-for="(value,nam) in partnerPubs" :key="nam">
            <div class="publication-title">
                <h2 class="publication-title-text"><router-link :to="`/OnepartnerDataset?name=${nam}`">{{nam}}</router-link></h2>
            </div>  
            <div class="publication-main" v-for="p in value" :key="p.title">      
                <ul>
                    <li>
                        <p class="publication-topic"></p>
                        <a :href=p.uri><p class="publication-titles">{{p.title}}</p></a>
                        <span class="publication-creator" v-if="type(p.creator)"><a :href=linkPerson(p.creator)>{{p.creator}}</a></span>
                        <span class="publication-creator" v-else >
                            <span v-for="namec in p.creator" :key="namec"><a :href=linkPerson(namec)>{{namec}}</a>; </span>
                        </span>
                        <span class="publication-creator"> -- {{p.date}} </span> <!--{{p.topic}}-->
                        </li>
                </ul>
            </div>
            
        </div>

    </div>

  </div>
    
  
</template>

<script>
// import axios from 'axios';
import { get } from '../utils/request'

export default {
    data(){
        return {
            msg:'',
            partnerPubs:{},
            creators:[]
        }
    },
    methods:{
        getMessage() {
            // const path = `${this.GLOBAL.BASE_URL}dataset`;
            // axios.get(path)
            get("dataset",null,false)
            .then((res) => {
                // console.log(res.data);
                this.partnerPubs = res.data;
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        type(creator){
            if(Array.isArray(creator)){
                return false;
            }else{
                return true;
            }
        },
            linkPerson(name){
                name=name.replace(" ","+")
                name=name.replace(",", "%2C")
                name="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q="+name+"&btnG="
                return name
            }

    },
    created() {
        this.getMessage();
    }

}
    
    


</script>

<style>

</style>