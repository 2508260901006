
export const color =  { 
  "digital":['#00496f'],
  "SDG-1": ['#e5243b'],
  "SDG-2": ['#dda83a'],
  "SDG-3": ['#4c9f38'],
  "SDG-4": ['rgba(197, 25, 45, 1)'],
  "SDG-5": ['rgba(255, 58, 33, 1)'],
  "SDG-6": ['rgba(38, 189, 226, 1)'],
  "SDG-7": ['rgba(252, 195, 11, 1)'],
  "SDG-8": ['rgba(162, 25, 66, 1)'],
  "SDG-9": ['rgba(253, 105, 37, 1)'],
  "SDG-10": ['rgba(221, 19, 103, 1)'],
  "SDG-11": ['rgba(253, 157, 36, 1)'],
  "SDG-12": ['rgba(191, 139, 46, 1)'],
  "SDG-13": ['rgba(63, 126, 68, 1)'],
  "SDG-14": ['rgba(10, 151, 217, 1)'],
  "SDG-15": ['rgba(86, 192, 43, 1)'],
  "SDG-16": ['rgba(0, 104, 157, 1)'],
  "other": ['#00396f']
        };


