<template>
    <div class="body_container">
    <div class="publication-body-element">
        <LoadingMessage 
            :messageDisplay=messageDisplay 
            :messageStatus=messageStatus 
        />

        <div v-if="!messageDisplay" class="publication-element">
            <div class="publication-title">
                <h2 class="publication-title-text">{{getaffichename($route.query.name)}}</h2>40 Recent publications
            </div>  
            <div class="publication-main" v-for="(p, index) in partnerPubs" :key="index">      
                <ul>
                    <li>
                        <Pub
                            :p = p
                            :partner=true />
                    </li>
                </ul>
            </div>
        </div>

        
    </div>
  </div>
</template>
<script>
// import axios from 'axios';
import { get } from '../utils/request'
import LoadingMessage from '../components/LoadingMessage'
import store from '../store';
import Pub from '../components/Pub';

export default {
    name:"OnePartner",
    components:{
        LoadingMessage,
        Pub
    },
    data(){
        return {
            msg:'',
            partnerPubs:{},
            partner:[],

            messageDisplay: false,
            messageStatus: "loading",
        }
    },
    methods:{
        getMessage() {
            this.messageDisplay = true;
            this.messageStatus = "loading";

            get(`partnerDetails/${this.$route.query.name}`, null, true)
            .then((res) => {
                if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    this.partnerPubs = res.data;
                    if(this.partnerPubs.length == 0){
                        this.messageStatus = "no result";
                    }else{
                        this.messageDisplay = false;
                    }
                }
            })
            .catch((error) => {
            // eslint-disable-next-line
            this.messageStatus = "service error";
            console.error(error);
            });
        },
        type(creator){
            if(Array.isArray(creator)){
                return false;
            }else{
                return true;
            }
        },
            linkPerson(name){
                return store.linkPerson(name);
            },
            getaffichename(un){
                return store.getNameAffiche(un)
            }

    },
    mounted(){
            
            // console.log(this.$route)
        },
    created() {
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        }  
        
        this.getMessage();
        this.getpartnerdata(); // function at main.js
        window.scrollTo(0,0);

    }

}
    
    


</script>

<style>

</style>