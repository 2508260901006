import VueRouter from "vue-router"
import Home from '../pages/Home'
import PerTopic from '../pages/PerTopic'
import PerPartner from '../pages/PerPartner'
import OnePartner from '../pages/OnePartner'
import OneTopic from '../pages/OneTopic'
import Sdg from '../pages/Sdg'
import KeyNumbers from '../pages/KeyNumbers'
import Result from '../pages/Result'
import Collaboration from '../pages/Collaboration'
import About from '../pages/About'
import Dataset from '../pages/Dataset'
import Onepardataset from '../pages/Onepardataset'
import Community from '../pages/Community'
import Projects from '../pages/Projects'
import OneProject from '../pages/OneProject'
import Resources from '../pages/Resources'
import Pub from '../components/Pub'

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/home',
            component: Home},
        {
            path: '/',          
            redirect:'/home'},
        {
            path: '/about',
            component: About},
        {
            path: '/dataset',
            component: Dataset},
        {
            path:'/OnepartnerDataset',
            component: Onepardataset},
        {
            path: '/pertopic',
            component: PerTopic},
        {
            path: '/perpartner',
            component: PerPartner},
        {
            path:'/oneTopic',
            component:OneTopic},
        {
            path: '/sdg',
            component: Sdg},
        {
            path: '/keynumbers',
            component: KeyNumbers},
        {
            name:'Result',
            path:'/result',
            component:Result},
        {
            path: '/Collaboration',
            component: Collaboration},
        {    
            path: '/Resources',
            name: "Resouces",
            component: Resources} ,
        {    
            path: '/Community',
            name: "Community",
            component: Community},
        {    
            path: '/Projects',
            name: "Projects",
            component: Projects},
        {    
            path: '/OneProject',
            name: "OneProject",
            component: OneProject},
        {   
            path:'/OnePartner',
            name:"OnePartner",
            component:OnePartner,},  
        {
            path: '/Pub',
            name: "Pub",
            component: Pub
        },
    ]
})