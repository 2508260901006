
import { Loading } from "element-ui";

// The loading box is set to be partially refreshed, and the loading box is closed when all requests are completed.
let loading
let needLoadingRequestCount = 0 // Declare an object to store the number of requests
function startLoading (targetdq) {
    loading =  Loading.service({
    lock: true,
    text: 'Loading...',
    fullscreen: false,
    background: 'rgba(255,255,255,.01)',
    target: targetdq, // Setting the loading animation area
    customClass: "el-loading-map",
  })
    setTimeout(function(){
      loading.close();
    }, 10000)
}
 
function endLoading () {
    loading.close()
}
export function showFullScreenLoading (targetdq) {
  if (needLoadingRequestCount === 0) {
    startLoading(targetdq)
  }
  needLoadingRequestCount++
}
export function hideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
 
export default {
  showFullScreenLoading,
  hideFullScreenLoading
}
