<template>
    <div class="body_container">
    <div class="col_body">

        <!-- title -->
        <p class="col_des">
            Digital resources provided by our partners
        </p>
        
        <div class="collab_container resources_container">
            
            <Filters 
                :filter="filter"
                :total="total"
                @filterChange="getResult"
            />
            
            <div class="resources_right">
                
                <LoadingMessage 
                    :messageDisplay=messageDisplay 
                    :messageStatus=messageStatus 
                />
                <div v-if="!messageDisplay"> 

                    <div v-for="element in dataShow" :key="element.name">
                        <div class="publication-element">
                            
                            <div class="publication-main" > 
                                <div class="resources_margin">
                                    <a target="_blank" :href="element.url" class="resources_title">{{ element.name }}</a>
                                    <div class="resources_school"><a target="_blank" :href="element.uniinfo.urlhome">{{element.uniinfo.NameAffiche}} ({{ element.uniinfo.longName }})</a></div>
                                </div>
                                <table class="resources_table"> 
                                    <tr v-if="element.type.length!=0"><td>Type</td><td><div v-for="ele in element.type" :key="ele">{{ ele }}</div></td></tr>
                                    <tr v-if="element.access!=null && element.access!='' "><td>Access</td><td>{{ element.access }}</td></tr>
                                    <tr v-if="element.description!=null && element.description !=''"><td>Description</td><td>{{ element.description }}</td></tr>
                                    <tr v-if="element.url!=null && element.url!=''"><td>URL</td><td><a target="_blank" :href="element.url">{{ element.url }}</a></td></tr>
                                    <tr v-if="element.audience.length!=0"><td>Audience</td><td><div v-for="au in element.audience" :key="au">{{ au }}</div></td></tr>
                                    <tr v-if="showelement(element.contact)"><td >Contact</td><td><div v-for="(value) in element.contact" :key="value.email"><div>{{ value.detail }}</div><div><a :href=emailad(value) >{{value.email}}</a></div><div><a target="_blank" :href="value.url">{{ value.url }}</a></div></div></td></tr>
                                    <tr v-if="element.language.length!=0"><td>Language</td><td><div v-for="lang in element.language" :key="lang">{{ lang}}</div></td></tr>
                                </table>
                            </div>
                        </div>

                    </div>

                    <pagination
                        :data = this.resources
                        :display=display
                        @returnData="showPage" 
                    />
                </div>
                
                
                
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import Filters from '../components/Filters'
import Pagination from '../components/pagination'
import LoadingMessage from '../components/LoadingMessage'
import { get } from '../utils/request'
    
export default {
    components:{
        Filters,
        Pagination,
        LoadingMessage
    },
    data(){
        return {
            resTotal: [],
            resources:[],
            filter:{
                university:[],
                access:[],
                type:[],
                audience:[],
                language:[]
            },
            search:{
                university:[],
                access:[],
                type:[],
                audience:[],
                language:[]
            },
            total: 0,     // nb pubs total
            display: 10,   // nb max in one page
            dataShow:[],  //data of this page

            messageDisplay: false,
            messageStatus: "loading",
        }
    },
    methods:{
        getdata(){
            this.messageDisplay = true;
            this.messageStatus = "loading";

            const path = `resources`;
            get(path, null, true)
            .then((res) => {
                if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    this.resources = res.data;
                    this.resTotal = this.resources;
                    this.total=this.resources.length;
                    this.getFilter();
                    if(this.resources.length==0){
                        this.messageStatus = "no result"
                    }else{
                        this.messageDisplay = false;
                    }
                }  
            })
            .catch((error) => {
                // eslint-disable-next-line
                this.messageDisplay = true;
                this.messageStatus = "service error";
                console.error(error);
            });
        },
        
        getFilter(){
            var filter = {
                university:[],
                access:[],
                type:[],
                audience:[],
                language:[]
            }

            for (var i = 0; i<this.resTotal.length; i++){
                if(!filter.university.includes(this.resTotal[i].university)){
                    filter.university.push(this.resTotal[i].university)
                }

                if(!filter.access.includes(this.resTotal[i].access)){
                    filter.access.push(this.resTotal[i].access)
                }

                for (var j = 0; j<this.resTotal[i].type.length; j++){
                    if(!filter.type.includes(this.resTotal[i].type[j])){
                        filter.type.push(this.resTotal[i].type[j])
                    }
                }

                for (j = 0; j<this.resTotal[i].audience.length; j++){
                    if(!filter.audience.includes(this.resTotal[i].audience[j])){
                        filter.audience.push(this.resTotal[i].audience[j])
                    }
                }

                for (j = 0; j<this.resTotal[i].language.length; j++){
                    if(!filter.language.includes(this.resTotal[i].language[j])){
                        filter.language.push(this.resTotal[i].language[j])
                    }
                }
            }
            this.$set(this, "filter", filter)
        },

        // get content for a certain page number
        showPage(dataShow){
            if(dataShow.length == 0){
                this.messageDisplay = true; 
                this.messageStatus = "no result"; 
            }else{
                this.dataShow = dataShow;
                this.messageDisplay = false;  
                document.documentElement.scrollTop = 0;
            }
        },
        
        // get result according to all filters
        getResult(searchEl){
            this.search = searchEl
            if (Object.keys(this.search).length === 0){
                this.getdata();
            }else{
                var result = this.resTotal
                for(var key in this.search){
                    result = this.filterList(key, result) 
                }
                this.resources = [...new Set(result)]
            }
            this.total=this.resources.length;
        },

        // get the corresponding content for one  filtering criterion
        filterList(filter, list){
            if(this.search[filter].length>0){
                var result = []
                for(var i = 0; i<this.search[filter].length; i++){
                    var ruler = this.search[filter][i]
                    for(var j =0; j<list.length; j++){
                        if (list[j][filter].indexOf(ruler) != -1){
                            result.push(list[j])
                        }
                    }
                }
                return result
            }
            return list
        },

        // open another window to write to the person
        emailad(value){
            if(value.email!=null){
                return 'mailto:'+value.email;
            }
            else{
                return ''
            }
        },

        showelement(el){
            if(el.length == 0){
                return false
            }else if(el[0]['email']==null && el[0]['url'] == null && el[0]['detail'] == null){
                return false   
            }else{
                return true
            }   
        },
    },
    created(){
        this.getdata();
    }
}
</script>