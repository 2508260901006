<template>
    <div>
    <div class="keynumbers-body">
        <div class="keynumbers-main">
            <!-- first part -->
            <div class="keynumber-subtitle-div">
            </div>
            <div class="keynumbers-chart1">
                <Pietotal/> 
            </div>
    
            <!-- second part -->
            <div class="keynumbers-chart1">
                <div class="keynumbers_part1">
                    <div class="graph_container_pie">
                        <BarChart/>
                        <div class="keynumbers_notice"> 
                            <p> *We analyse English publications only</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- third part -->
            <div class="keynumber-subtitle-div">
            </div>
            <div class="keynumbers-chart1">
                <div class="keynumbers_part1">
                    <div class="keynumbers-main-uni">
                        <BarChartProgress/> 
                        <p> *We analyse English publications only</p>
                    </div>
                </div>
            
            </div>

            <!-- fourth part -->
            <div class="keynumbers-chart1">
                <div class="keynumbers_part1">
                    <div class="keynumbers-main-uni">
                        <p> Top authors</p>
                        <!-- <table class="keynumbers_author_table"> 
                            <tr v-for="(person, index) in authors" :key="index" :class="{'keynumbers_top':index==0}"> 
                                <td v-if="index==0"><img class="keynumbers_medal" src="../assets/medal.png"/></td>
                                <td class="keynumbers_number" v-else>{{ index + 1 }}</td>
                                <td>{{ person._id }}</td>
                                <td>{{ person.count }}</td>
                            </tr>
                        </table> -->

                        <table class="keynumbers_author_table"> 
                            <tr v-for="(person, index) in authors" :key="index"> 
                                <td class="keynumbers_number">{{ index + 1 }}</td>
                                <td>{{ person._id }}</td>
                                <td>{{ person.count }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            
            </div>
        </div>
    
        <div class="keynumbers-sidebar">
            <div class="keynumbers-sidebar-top">
                <div class="keynumbers-sidebar-top-div">
                    <p class="keynumbers-sidebar-top-text">SDG topics</p>
                </div>
                <div>
                    <!-- <router-link to="/sdg"><button id="keynumbers-moreinfo-topic" name="moreinfo" class="keynumbers-moreinfo-topic">MORE INFO</button></router-link> -->
                </div>
            </div>
    
            <div> 
                <div class="keynumbers-photo-div"> 
                    <img src="../assets/E-WEB-Goal-01.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-02.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-03.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-04.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-05.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-06.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-07.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-08.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-09.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-10.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-11.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-12.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-13.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-14.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-15.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-16.png" class="keynumbers-sdg-element-photo">
                    <img src="../assets/E-WEB-Goal-17.png" class="keynumbers-sdg-element-photo">
                </div>
            </div>
            <div>
    
            </div>
    
        </div>
        
    </div>
    </div>
    </template>
    <script>
    import BarChart from '../components/BarChart'
    import BarChartProgress from '../components/BarChartProgress'
    import Pietotal from '../components/Pietotal'
    // import {chartData, chartDatasetDIGITAL, chartDatasetSDG1, chartDatasetSDG2, chartDatasetSDG3, chartDatasetSDG4, chartDatasetSDG5, chartDatasetSDG6, chartDatasetSDG7, chartDatasetSDG8, chartDatasetSDG9, chartDatasetSDG10, chartDatasetSDG11, chartDatasetSDG12, chartDatasetSDG13, chartDatasetSDG14, chartDatasetSDG15, chartDatasetSDG16, chartDatasetSDG17} from "../Data/BarChartProgress.js"
    // import axios from 'axios';
    import { get } from '../utils/request'
    
    
    export default {
        name:'KeyNumbers',
        components:{
            BarChart,
            BarChartProgress,
            Pietotal
        },
        data(){
            return{
                authors:{},
            }
        },
        methods:{
            getTopAuthors(){
                // const path = `${this.GLOBAL.BASE_URL}/topauthor`;
                // axios.get(path)
                get("topauthor", null, false)
                .then((res) => {
                    this.authors=res.data;
                })
                .catch((error) => {
                // eslint-disable-next-line
                console.error(error);
                });
            },
            
       },
       
        created() {
            this.getTopAuthors();
            window.scrollTo(0,0);
            
        }
    
        }
    
    </script>
    <style>
    
    </style>
    