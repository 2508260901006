<template>
    <div>
      <header>

            <!-- <div id="top_test"> 
                <span class="top_test_text">This website is a beta version, you are welcome to leave your feedback <router-link to="/about"><span class="top_test_here">HERE</span></router-link>.</span>
                <input type="button" value="Close" id="top_test_close" @click="closeTest()">
            </div> -->

            <div class="top-header">
              <div class="container">
                  <div class="top-header-right">   
                  <ul>
                      <li class="top-header-icon">
                          <a href="http://www.facebook.com/engage.eu" aria-label="Facebook" target="_blank">
                              <img class="icon_socialmedia" src="../assets/facebook-icon.png" >
                          </a>
                      </li>
                      <li class="top-header-icon">
                          <a href="https://www.instagram.com/engage_eu/" aria-label="Facebook" target="_blank">
                              <img class="icon_socialmedia" src="../assets/ins-icon.png" >
                          </a>
                      </li>
                      <li class="top-header-icon">
                          <a href="https://twitter.com/eu_engage" aria-label="Facebook" target="_blank">
                              <img class="icon_socialmedia" src="../assets/twitter-icon.png" >
                          </a>
                      </li>
                      <li class="top-header-icon">
                          <a href="https://www.linkedin.com/company/engage-eu" aria-label="Facebook" target="_blank">
                              <img class="icon_socialmedia" src="../assets/linkdin-icon.png" >
                          </a>
                      </li>
                      <li class="top-header-icon">
                          <a href="https://www.youtube.com/channel/UCzptYn8lP968z4wu4yCEd4Q" aria-label="Facebook" target="_blank">
                              <img class="icon_socialmedia" src="../assets/youtube-icon.png" >
                          </a>
                      </li>
                  </ul>
                  </div>
              </div>
            </div>
  
            <div class="top-main">
              <div class="container" id="top-main-container">
                  <div class="top-main-logo">
                      <a href="https://www.engageuniversity.eu/" aria-label="Facebook" target="_blank">
                          <img class="icon_engage" src="../assets/ENGAGE EU Logo.jpg" >
                      </a>
                  </div>
                  <ul class="top-main-menu">
                    <li class="top-main-menu-text"><router-link to="/home"><button>HOME</button></router-link></li>
                    <li class="top-main-menu-text"><Pubsmenu/></li>
                    <li class="top-main-menu-text"><router-link to="/Resources"><button>Resources</button></router-link></li>
                    <li class="top-main-menu-text"><router-link to="/Projects"><button>Projects</button></router-link></li>
                    <li class="top-main-menu-text"><router-link to="/keynumbers"><button>KEY NUMBERS</button></router-link></li>
                    <li class="top-main-menu-text"><topicmenu/></li>
                    <li class="top-main-menu-text"><router-link to="/Collaboration"><button>COLLABORATION</button></router-link></li>
                    <!-- <li class="top-main-menu-text"><router-link to="/dataset"><button>Dataset</button></router-link></li> -->
                    <li class="top-main-menu-text"><router-link to="/Community"><button>Community platform</button></router-link></li>
                    <li class="top-main-menu-text"><router-link to="/about"><button>About</button></router-link></li>

                </ul>
                    <!-- <ul class="top-main-profil-glo">
                        <li><button class="top-main-profil"><img class="icon_profil" src="../assets/profil-icon.png"></button></li>
                    </ul> -->

              </div>
          
          
            </div>
          <!-- <div class="top-bottom">
              <div class="container">
                  <div class="top-bottom-text">
                      <p>THE EUROPEAN UNIVERSITY ENGAGED IN SOCIETAL CHANGE</p>
                  </div>
              </div>
          </div> -->
          
  
      </header>
      </div>
  </template>
  
  <script>
  import Pubsmenu from './Pubsmenu'
  import Topicmenu from './Topicmenu' 
  export default {
      name:'Banner',
      components:{
          
          Pubsmenu,
          Topicmenu
      },
      methods:{
        closeTest(){
            document.getElementById("top_test").style.cssText = "display: none;";
        }
      }
  }
  </script>
  
  <style>
  
  </style>