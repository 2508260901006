<template>
    <footer>
        <div class="footer-container">
            <div class="footer-left">
                <p class="footer-repository-title">Alliance members' publication repository (Partially Open Access)</p>
                <div v-for="pa in partners" :key="pa.id">
                    <p class ="footer-repository" v-if="pa.urlOARepository!=''">
                        <a class="footer-repository-a" :href="pa.urlOARepository">{{pa.longName}}({{pa.NameAffiche}})</a>
                    </p>
                </div>    
            </div>

            <div class="footer-right">
                <div class="footer-right-beauty">
                    <p class="footer-text">contact us</p>
                    <a href="mailto:engage-cellule@ut-capitole.fr" ><p class="footer_text_contact">engage-cellule@ut-capitole.fr</p></a>
                    <router-link to="/about"><p class="footer_text_contact" >feedback box</p></router-link>
                    <div class="logo_footer_container">
                        <img class="logo_footer" src="../assets/csm_logos.jpg" alt="csm_logos"/>
                    </div>
                </div>
            </div>
        </div>

        
    </footer>
</template>
<script>

// import axios from 'axios';
import { get } from '../utils/request'
import store  from '../store'
export default {
    name : 'pagefooter',
    data(){
        return{
            partners:[]
        }
    },
    methods:{
        // getPartners(){
        //     // const path = `${this.GLOBAL.BASE_URL}/`;
        //     // axios.get(path)
        //     get('partnerinfo', null, false)
        //     .then((res) => {
        //         // console.log(res.data);
        //         this.partners = res.data;
        //         // console.log(this.partners)
        //     })
        //     .catch((error) => {
        //     // eslint-disable-next-line
        //     console.error(error);
        //     });
        // },
        getPartners(){
            setTimeout(() => {
            this.$set(this, 'partners', store.getSharedVariable());
        }, 2000) 
        }
    },
    created() {
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        } 

        this.getPartners();
        
    }
}
</script>

