<template>
    <div class="keynumbers_part1">
        <span class="keynumbers_part1_expli">Select one university to see percentage of publications with topic</span>
        <select name="LeaveType" @change="onChange($event)" v-model="selected" class="keynumber_select">
            <option value="total">Total</option>
            <option v-for="pa in universitylabels" :key="pa" :value="pa">{{ getaffichename(pa) }} </option>
        </select>
        <div class="graph_container_pie"><Pie :chartData="chartConfig" :chart-options="options" /></div>
        <div class="graph_container_pie"><Pie :chartData="chartConfig1" :chart-options="options1" /></div>
        
        
    </div>
  
</template>

<script>
// import axios from 'axios';
import { get } from '../utils/request'
import store from '../store';
import { Chart as ChartJS,Title, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title,ArcElement, Tooltip, Legend, ChartJSPluginDatalabels)

export default {
    components: {
        Pie
    },
    data(){
        return{
            universitylabels:[],
            chartConfig:{
                labels: ['English', 'Other'],
                datasets: [
                    {
                    backgroundColor: ['#41B883', '#E46651'],
                    data: []
                    }
                ]
            },
            chartConfig1:{
                labels: ['Engage/SDG topic', 'Other'],
                datasets: [
                    {
                    backgroundColor: ['#41B883', '#E46651'],
                    data: []
                    }
                ]
            },
            options:{
                responsive: true,
                plugins:{
                    title:{
                        display: true, 
                        text:"Percentage of publications in English",
                        font:{
                        size:20,
                        family: " system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                        }
                    },
                    datalabels:{
                        formatter: (value, context) => {
                            let sum = 0;
                            let dataArr = context.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage = (value*100 / sum).toFixed(2)+"%";
                            let display = [`${value}`,`${percentage}`]
                            return display;
                        },
                        color: '#fff',
                    }            
                },
                maintainAspectRatio: false
            },
            options1:{
                responsive: true,
                plugins:{
                    title:{
                        display: true, 
                        text:"Percentage of engage/SDG topics in the English publications",
                        font:{
                        size:20,
                        family: " system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                        }
                    },
                    datalabels:{
                        formatter: (value, context) => {
                            let sum = 0;
                            let dataArr = context.chart.data.datasets[0].data;
                            dataArr.map(data => {
                                sum += data;
                            });
                            let percentage = (value*100 / sum).toFixed(2)+"%";
                            let display = [`${value}`,`${percentage}`]
                            return display;
                        },
                        color: '#fff',
                    }            
                },
                maintainAspectRatio: false
            },
            parschool:{},
            percen:0,
            selected:"total",
            partner:[]
        }
    },
    methods:{
        onChange(event) {
            this.piepart=event.target.value;
            if(this.piepart!='total'){
                this.getGeNb(this.piepart);
            }
            else{
                this.total_nu();
            }
            
        },
        total_nu(){
            
            
            var t=this.parschool;
            var total=0;
            var total_topic_en=0;
            var total_topic_raw=0;
            for(var i in t){
                // console.log(i);
                total+=t[i]['total_pubs'];
                // console.log(t[i]['total_pubs']);
                total_topic_en =total_topic_en+t[i]['total_pubs_en'];
                total_topic_raw =total_topic_raw+t[i]['total_pubs_raw'];
            }
            // console.log(total_topic);
            this.chartConfig.datasets[0].data=[total_topic_en,total_topic_raw-total_topic_en];
            this.chartConfig1.datasets[0].data=[total,total_topic_en-total];
            this.percen=((total_topic_en/total_topic_raw)*100).toFixed(2)
        },
        getGeNb(par){
            // const path = `${this.GLOBAL.BASE_URL}/chart`;
            // axios.get(path)
            // .then((res) => {
                // console.log(res.data[par]["total_pubs"]);
                this.chartConfig.datasets[0].data=[this.parschool[par]["total_pubs_en"],this.parschool[par]["total_pubs_raw"]-this.parschool[par]["total_pubs_en"]];
                this.chartConfig1.datasets[0].data=[this.parschool[par]["total_pubs"],this.parschool[par]["total_pubs_en"]-this.parschool[par]["total_pubs"]];
                this.percen=((this.parschool[par]["total_pubs_en"]/this.parschool[par]["total_pubs_raw"])*100).toFixed(2)
                // console.log(this.percen);
                // this.chartConfig.datasets[0].data[1]=res.data[par]["total_pubs"];
            // })
            // .catch((error) => {
            // // eslint-disable-next-line
            // console.error(error);
            // });
        },
        getChartNb(){
            // const path = `${this.GLOBAL.BASE_URL}/chart`;
            // axios.get(path)
            get('chart', null, false)
            .then((res) => {
                this.parschool=res.data;
                this.total_nu();
                for (var i in this.parschool){
                    this.universitylabels.push(i)
                }
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        getaffichename(un){
            return store.getNameAffiche(un)
        }
    },
    created(){
        this.getChartNb();
        // this.getpartnerdata();

        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        }  
    }
}
</script>

<style>

</style>