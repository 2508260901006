<template>
    <div class="body_container">

        <LoadingMessage 
            :messageDisplay=messageDisplay 
            :messageStatus=messageStatus 
        />

        <div v-if="!messageDisplay"> 
            <div class="publication-body" v-if="ready">
                <!-- espace between banner and body, don't delete-->
            <div> 
                <p class="update-date"></p> 
            </div> 
            <div v-for="element in topic" :key="element.topic">
                <div class="publication-element"  v-if="element.pubs.length!=0">
                    <div class="publication-title">
                        <h2 class="publication-title-text"><router-link :to="`/oneTopic?name=${element.topic}&topic=${element.topicshortname}`">{{element.topic}} - {{element.topicshortname}}</router-link></h2>
                    </div>
                    <div class="publication-main">      
                        <ul>
                            <li class="publication-text" v-for="pub in element.pubs" :key="pub.title">
                                <Pub
                                :p = pub
                                :topic=true />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>
    
    </div>

</template>

<script>
// import axios from 'axios';
import { get } from '../utils/request'
import LoadingMessage from '../components/LoadingMessage'
import store from '../store';
import Pub from '../components/Pub';

export default {
    components:{
        LoadingMessage,
        Pub
    },
    data(){
        return{
            pubs:{},
            partner:[],
            topic:[],
            ready: false,
            
            messageDisplay: false,
            messageStatus: "loading",
        }
    },
    methods: {
        getTopics(){
            this.messageDisplay = true;
            this.messageStatus = "loading";

            get(`keywords`, null, true)
            .then((res) => {
                this.topic = res.data;   
            })
            .then(() => {
                this.getMessage();
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            this.messageStatus = "service error";
            });
        },
        getMessage() {
            get("topics", null, false)
            .then((res) => {
                // console.log(res.data);
                this.pubs = res.data;
                
                if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    if(Object.keys(this.pubs).length==0 || this.topic.length==0){
                        this.messageStatus = "no result";
                    }else{
                        //put the articles at the right place of array "topic"
                        for (var j = 0; j<this.topic.length; j++){
                            var cpt = 0
                            for (var i = 0; i<Object.keys(this.pubs).length; i++){
                                if(this.topic[j]["topic"]==Object.keys(this.pubs)[i]){
                                    this.topic[j]["pubs"] = Object.values(this.pubs)[i];
                                    cpt = cpt + 1;
                                }
                            }
                            if(cpt==0){
                                this.topic[j]["pubs"] = []
                            }
                        }

                        // console.log(this.topic)
                        this.ready = true;

                        this.messageDisplay = false
                    }
                }  

                
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            this.messageStatus = "service error";
            })
        },
        type(creator){
            if(Array.isArray(creator)){
                return false;
            }else{
                return true;
            }
        },
        showTopic(name){
            for(let index in this.topic){
                if(this.topic[index].topic==name){
                    return this.topic[index].topicshortname

                }
            }
        },
        linkPerson(name){
            return store.linkPerson(name);
        },
        getaffichename(un){
            return store.getNameAffiche(un);
        }
    },
    created() {
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        }  

        this.ready = false;
        this.getTopics();
        this.getpartnerdata();
        // console.log(this.partner)
    }
}
</script>

<style>

</style>