<template>
    <div class="body_container">
    <div class="col_body">
        <!-- title -->
        <p class="col_des">
            Projects with publication outputs in our database
        </p>

        <div class="resources_container collab_container"> 
            
            <!-- filter -->
            <Filters 
                :filter="filter"
                :total="total"
                @filterChange="getResult"
            />
 
            <!-- main content -->
            <div class="resources_right">

                <LoadingMessage 
                    :messageDisplay=messageDisplay 
                    :messageStatus=messageStatus 
                />
                
                <div v-if="!messageDisplay">
                    <div v-for="(project, index) in dataShow" :key="index">
                    <div class="publication-element">
                        <Project :project="project"/>
                    </div>
                    </div>

                    <pagination
                        :data = projects
                        :display=display
                        @returnData="showPage" 
                    />

                </div>
                
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import Project from '../components/Project'
import Pagination from '../components/pagination'
import Filters from '../components/Filters'
import LoadingMessage from '../components/LoadingMessage'
import { get } from '../utils/request'

export default {
    name: 'Collaboration',
    components:{
        Project,
        Pagination,
        LoadingMessage,
        Filters,
    },
    data(){
        return {
            allProjets: [],
            projects: [],
            filter:{},
            search:{
                partnerShortName:[],
                state:[],
                funders:[]
            },
            total: 0,     // nb pubs total
            display: 10,   // nb max in one page
            dataShow:[],  //data of this page

            messageDisplay: false,
            messageStatus: "loading",
        }
    },
    methods:{
        getProjects(){
            this.messageDisplay = true;
            this.messageStatus = "loading";

            const path = `projects`;
            get(path, null, true)
            .then((res) => {
                if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    this.allProjets = res.data;
                    this.projects = res.data;
                    this.getFilter();

                    this.total = this.projects.length
                    if(this.projects.length==0){
                        this.messageStatus = "no result"
                    }else{
                        this.messageDisplay = false;
                    }
                }

                
            })
            .catch((error) => {
            // eslint-disable-next-line
                console.error(error);
                this.messageDisplay = true;     
                this.messageStatus = "service error";
            });

        },

        getFilter(){
            let filter = {
                university : [],
                state: [],
                funders: []
            }

            for (var i = 0; i<this.allProjets.length; i++){
                this.allProjets[i].university = []
                for (var m = 0; m<this.allProjets[i].partnerShortName.length; m++){
                    if(!filter.university.includes(this.allProjets[i].partnerShortName[m])){
                        filter.university.push(this.allProjets[i].partnerShortName[m])
                    }
                    this.allProjets[i].university.push(this.allProjets[i].partnerShortName[m])
                }
            
                if(typeof(this.allProjets[i].state) == 'string'){
                    if(filter['state'].indexOf(this.allProjets[i].state) == -1){
                        filter['state'].push(this.allProjets[i].state);
                    }  
                }

                if(typeof(this.allProjets[i].funders) == 'string'){
                    if(filter['funders'].indexOf(this.allProjets[i].funders) == -1){
                        filter['funders'].push(this.allProjets[i].funders);
                    }  
                }else{
                    for(var t=0;t<this.allProjets[i]['funders'].length;t++){
                        if(filter['funders'].indexOf(this.allProjets[i]['funders'][t]) == -1){
                            filter['funders'].push(this.allProjets[i]['funders'][t]);
                        }
                    }
                }
            }
            this.filter = filter;

        },

        showPage(returnData){
            if(returnData.length == 0){
                this.messageDisplay = true; 
                this.messageStatus = "no result"; 
            }else{
                this.$set(this, "dataShow", returnData)
                this.messageDisplay = false;  
                document.documentElement.scrollTop = 0;

            }
        },

        getResult(searchEl){
            console.log(searchEl)
            this.search = searchEl
            if (Object.keys(this.search).length === 0 ){
                console.log('no res')
                this.getProjects();
            }else{
                console.log('not null')
                var result = this.allProjets
                for(var key in this.search){
                    result = this.filterList(key, result) 
                }
                this.projects = [...new Set(result)]
            }
            this.total=this.projects.length;
        },
        filterList(filter, list){
            if(this.search[filter].length>0){
                var result = []
                for(var i = 0; i<this.search[filter].length; i++){
                    var ruler = this.search[filter][i]
                    for(var j =0; j<list.length; j++){
                        if (list[j][filter].indexOf(ruler) != -1){
                            result.push(list[j])
                        }
                    }
                }
                return result
            }
            return list
        },
        engageLogo(topic){
            if(topic != null && topic>0){
                return true;
            }else{
                return false;
            }
        },
    },
    created(){
        this.getProjects();
    }
}
</script>