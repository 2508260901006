<template>
    <div class="graph_container keynulbers_filter_content">

      <div class="keynumbers_filter">
        
          <div class="keynumbers_filter_title">FILTERS</div>
        
          <FilterEl
            v-for="(value, keyFilter, index) in filter" 
            :key="index"
            :list="value"
            :keyFilter="keyFilter"
            @filterChange="check"
            ref="filterElRefs"
        />
          
      </div>



      <div class="bar_container">
        <Bar ref="bar"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </div>
    </div>
    </template>
    
    <script>
    import { Bar} from 'vue-chartjs'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
    // import axios from 'axios';
    import { get } from '../utils/request'
    import {color} from '../Data/BarChartProgress'
    import store from '../store';
import FilterEl from '../components/FilterEl'



    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
      name: 'BarChartProgress',
      components: { Bar, FilterEl },
      props: {
        chartId: {
          type: String,
          default: 'Nb of recent topics'
        },
        datasetIdKey: {
          type: String,
          default: 'label'
        },
        width: {
          type: Number,
          default: 400
        },
        height: {
          type: Number,
          default: 400
        },
        cssClasses: {
          default: '',
          type: String
        },
        styles: {
          type: Object,
          default: () => {}
        },
        plugins: {
          type: Object,
          default: () => {}
        }
      },
      data() {
        return {
            metadata : [],    // university short names with statistical data for each theme
            total : {},      // chartDate > datasets > labels > "TOTAL NUMBER OF PUBLICATIONS"
            filter:{},
            search:{},
            
            chartData : {
                labels: [],
                datasets: []
            },
            chartOptions: {
                responsive: true,
                plugins:{
                  title:{
                      display: true, 
                      text:"NB of publications for each university",
                      font:{
                      size:20,
                      family: " system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                      }
                  },
                  datalabels:{
                    display: false
                  }            
                },
                scales:{
                x:{
                    grid:{
                    display:false
                    }
                },

                y:{
                    grid:{
                    display:false
                    }
                }
                
                }
                
                }
                }
                

                },
      methods: {
        getChartNb(){
            // const path = `${this.GLOBAL.BASE_URL}/chart`;
            // axios.get(path)
            get('chart', null, false)
            .then((res) => {               
                this.metadata = res.data
                this.getFilterLabels();
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },

        // get filter "topic" and chartLabels
        getFilterLabels(){
                let filter = {
                  topic: []
                }
                let labels = []
                let datalist = []
                for (let [key, value] of Object.entries(this.metadata)) {

                  if(value.total_pubs != 0){
                    labels.push(store.getNameAffiche(key))
                    datalist.push(value.total_pubs);
                  }
                    for (var i=0; i<value.pubs.length; i++){
                      if (filter.topic.indexOf(value.pubs[i]['_id']) == -1){
                        filter.topic.push(value.pubs[i]['_id'])
                      }
                    }
                }
                this.filter = filter
                this.chartData.labels = labels

                this.total = {"label": "TOTAL NUMBER OF PUBLICATIONS", "data": datalist, 'backgroundColor': color["other"]}
                this.chartData['datasets'].push(this.total)
            },

        // show the right numbers according to topic chosen at filter
        check(keyFilter, searchEl){
          this.search[keyFilter] = searchEl
          console.log(this.search)
          this.chartData["datasets"] = []

          if(this.search.topic.length>0){
            for(var m =0; m<this.search.topic.length; m++){
              var topic = this.search.topic[m]
              var datalist = []   
              for(var i=0;i<this.chartData["labels"].length;i++){
                for(var j in this.metadata){
                  if(j==store.getShortName(this.chartData["labels"][i]) ){ // transform nameaffiche to shortname
                    for (var p in this.metadata[j]["pubs"]){
                      if(this.metadata[j]["pubs"][p]["_id"]==topic){
                        datalist[i]=this.metadata[j]["pubs"][p]["counts"];
                      }
                    }
                  }
                }
              }
              
              if(topic in color){
                this.chartData['datasets'].push({"label": topic, "data": datalist, 'backgroundColor': color[topic]})
              }else{
                this.chartData['datasets'].push({"label": topic, "data": datalist, 'backgroundColor': color["other"]})
              }
                     
          }
          }else{
            this.chartData['datasets'] = []
            this.chartData['datasets'].push(this.total)
          }
          
            },
      },

      created() {
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        }  

        this.getChartNb();
      }
        
      }
    
    </script>
<style> 
.keynumbers_filter{
    width: 8em;
    padding: 0.5em;
    background-color: rgba(247, 249, 251,1);
    color: #666666;
}
.keynumbers_filter_title{
    margin-right: 2em;
    font-size: 1.3em;
    margin-top: 0.2em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}  
</style>