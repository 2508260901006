<template>
    <div class="resources_menu"> 
        <p class="resources_filter">Filters ({{ total }})</p> 
        <div @click="clear()" class="resources_clear">Clear all</div>
        <FilterEl
            v-for="(value, keyFilter, index) in filter" 
            :key="index"
            :list="value"
            :keyFilter="keyFilter"
            @filterChange="postValue"
            ref="filterElRefs"
        />
               
    </div>
</template>
<script>
import FilterEl from '../components/FilterEl'
export default{
    name:"Filters",
    components:{
        FilterEl,
    },
    props:{
        filter:{
            type: Object,
            default: () => ({})
        },
        total:{
            type: Number
        },
    },
    data(){
        return{
            search:{},
        }
       
    },
    methods:{
        postValue(keyFilter, searchEl){
            this.search[keyFilter] = searchEl
            for (const [key, value] of Object.entries(this.search)) {
                // console.log(`Key: ${key}, Value: ${value}`);
                if(value.length==0){
                    delete this.search[key]
                }
            }
            this.$emit("filterChange", this.search)
            // console.log(this.search)
        },
        clear(){
            this.$refs.filterElRefs.forEach(filterEl => {filterEl.clearFilter()});
        }
    },

}
</script>
