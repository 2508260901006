<template>
    <div class= "body_container"> 

        <LoadingMessage 
            :messageDisplay=messageDisplay 
            :messageStatus=messageStatus 
        />

        <!-- <div> 
            <p @click="returnback()"> Return </p>
        </div> -->
        
        <div v-if="!messageDisplay" class="publication-body">
        <div class="oneproject_basicinfo"> 
            <div class="resources_margin">
                <span class="resources_title">{{ project.title }}</span>
                <p class="sous_titre">{{project.state}}</p>
            </div>
            
            <table class="resources_table"> 
                <tr v-if="'startdate' in this.project"><td>Start date </td><td>{{project.startdate}}</td></tr>
                <tr v-if="'enddate' in this.project"><td>End date </td><td>{{project.enddate}}</td></tr>
                <tr v-if="'funders' in this.project"><td>Funder </td><td>{{project.funders}}</td></tr>
                <tr v-if="'code' in this.project"><td>Project code </td><td>{{project.code}}</td></tr>
                <tr v-if="'partnerShortName' in this.project"><td>Participants ENGAGE </td><td><div v-for="uni in project.partnerShortName" :key="uni">{{ uni}}</div></td></tr>
            </table>

            <table class="project_table"> 
                <tr> 
                    <td v-if="hasSummary" @click="showSummary()"><span class="project_click" :class="{'project_onglet':display}">Summary</span></td><td @click="showPubs()"><span class="project_click" :class="{'project_onglet':!display}">Publications</span></td>
                </tr>
            </table>
        </div>

        

        <div v-if="hasSummary && show " id="oneproject_Summary"> 
            <div class="main_pub project_sum_text">
                {{project.summary}}
            </div>  
        </div>

        <div v-else id="oneproject_pubs">
            <div v-for="p in project.publicationsinfo" :key="p.uri">
                <div class="main_pub pubs_projects">
                    <img v-if="engageLogo(p.topic)" class="collaboration_logo_engage" src="../assets/ENGAGE EU Logo.jpg"> 
                    <p>
                        <Pub
                        :p = p
                        />
                    </p>
                    
                </div>
            </div>
        </div> 
    </div>
    </div>
</template>
<script>
// import axios from 'axios';

import { get } from '../utils/request'
import LoadingMessage from '../components/LoadingMessage'
import store from '../store';
import Pub from '../components/Pub.vue';

export default {
    name: 'OneProject',
    components:{
        LoadingMessage,
        Pub
    },
    data(){
        return{
            project:{},
            show: true,
            display: true,
            hasSummary: true,

            messageDisplay: false,
            messageStatus: "loading",
        }
    },
    methods:{
        getProject(){
            this.messageDisplay = true;
            this.messageStatus = "loading";
            // const path = ;
            // // const path = `${this.GLOBAL.BASE_URL}OneProject/772021`;
            // axios.get(path)
            get(`OneProject/${this.$route.query.code}`, null, true)
            .then((res) => {
                if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    // console.log(res.data);
                    this.project = res.data[0];
                    this.existSummary();

                    this.messageDisplay = false;
                }
                
            })
            .catch((error) => {
            // eslint-disable-next-line
            this.messageStatus = "service error";
            console.error(error);
            });

        },
        type(creator){
            if(Array.isArray(creator)){
                return false;
            }else{
                return true;
            }
        },
        linkPerson(name){
            return store.linkPerson(name);
        },
        showSummary(){ 
            this.show=true     // content
            this.display=true  // banner style
        },
        showPubs(){ 
            this.show=false 
            this.display=false
        },
        existSummary(){
            if ("summary" in this.project){
                this.hasSummary=true
            }else{
                this.hasSummary=false
                this.show=false 
                this.display=false
            }
        },
        engageLogo(topic){
            if(topic != null && topic!="other topic"){
                return true;
            }else{
                return false;
            }
        },
        returnback(){
            this.$router.go(-1)
        }
    },
    created(){
        this.getProject();
        
    }
}
</script>