<template>
  <div class="sdg_body_container"> 
    <div class="sdg-body">
  
      <!-- left slide -->
      <div class="sdg-next-div">
        <button class="sdg-next" v-on:click="prePage()"><img class="sdg-next-icon" src="../assets/fleche-gauche.png"></button>
      </div>

      <div class="sdg-div">
          <LoadingMessage 
            :messageDisplay=messageDisplay 
            :messageStatus=messageStatus 
          />

          <div v-if="!messageDisplay" class="sdg-div">

            <!-- topic introduction -->
            <div class="sdg-element" v-for="(el, index) in dataShow" :key="index">

              <div>
                <div class="sdg-element-title">
                  <h2 class="sdg-element-title-text">{{el.topic}}</h2>
                </div>
                <div class="sdg_element-photo-div">
                  <img :src="srcImg(el.pic, el.pictype)" class="sdg-element-photo">
                </div>
                <div class="sdg-element-description">
                  <span class="sdg_element_text">{{el.topicshortname}}</span><br/>
                  <span>{{el.topicFullName}}</span>
                </div>
              </div>

              <!-- button for more information -->
              <div v-if="typeof el.url != 'undefined'" class="sdg-element-lien">
                <a :href="el.url" target="_blank"><button class="sdg-element-lien-button">MORE INFO</button></a>
              </div>

            </div>
          </div>
      </div>
  
      <!-- right slide -->
      <div class="sdg-next-div">
        <button class="sdg-next" v-on:click="nextPage()"><img class="sdg-next-icon" src="../assets/fleche-droite.png"></button>
      </div>
  
    </div>
  </div>
</template>
<script>
import LoadingMessage from '../components/LoadingMessage'
import { get } from '../utils/request'

export default {
    components:{
      LoadingMessage,
    },
    data(){
          return{
            topic:"",
            topics:[],
            total: 0,     // nb pubs total
            display: 3,   // nb max in one page
            currentPage: 0,   // current page, page1 default
            pageNum:1,    //nb pages total
            totalPage:[],  //data of each page
            dataShow:[],  //data of this page

            messageDisplay: false,
            messageStatus: "loading",
            }
        },
    methods:{
      // get information of topics
      getsdg(){
        this.messageDisplay = true;
        this.messageStatus = "loading";

        const path = `keywords`;
        get(path, null, true)
        .then((res) => {
            if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    if(res.data.length==0){
                      this.messageStatus = "no result"
                    }else{
                      this.messageDisplay = false;

                      this.topics = []
                      if(this.topic=="sdg"){
                        for(var i = 0; i<res.data.length; i++){
                          if(res.data[i]['topic'].indexOf("SDG") != -1){
                            this.topics.push(res.data[i])
                          }            
                        }
                      }else if(this.topic=="socchange"){
                        for(var j = 0; j<res.data.length; j++){
                          
                          if(res.data[j]['topic'].indexOf("SDG") == -1){
                            this.topics.push(res.data[j])
                          }            
                        }
                      }
                      
                      this.total=this.topics.length
                      this.pageNum= Math.ceil(this.total / this.display) ||1
                      for (let i = 0; i < this.pageNum; i++) {
                          this.totalPage[i] = this.topics.slice(this.display * i, this.display * (i + 1))
                      }
                      // show data of the first page
                      this.dataShow = this.totalPage[this.currentPage];
                        }
                    }           
        })
        .catch((error) => {
        // eslint-disable-next-line
          console.error(error);
          this.messageDisplay = true;
          this.messageStatus = "service error";
        });
      },
      // next group
      nextPage() {
            if (this.currentPage !== this.pageNum - 1) {
            this.dataShow = this.totalPage[++this.currentPage];
            }
            document.documentElement.scrollTop = 0;
        },
      // previous group
      prePage() {
          if (this.currentPage !== 0) {
          this.dataShow = this.totalPage[--this.currentPage];
          }
          document.documentElement.scrollTop = 0;
      },
      // show image
      srcImg(code, type){
          //function for create src of logo
          //data:image/png;base64,{code base64 of image}
          //data:image/jpeg;base64,{code base64 of image}
          //data:image/x-icon;base64,{code base64 of image}
          return "data:"+type+";base64,"+code   
        },
    },
    watch:{
      // switch content between SDG topics and no-SDG topics
      $route: {
        handler(){
            this.topic = this.$route.query.topic
            this.getsdg();
        }}
    },
    created(){
        this.topic = this.$route.query.topic
        this.getsdg();
      }
  }
  </script>
  
  <style>
  
  </style>
  