<template>
  <div class="doughnut-chart-container">
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
  </template>
  
  <script>
  import { Doughnut } from 'vue-chartjs/legacy';
  // import axios from 'axios';
  import { get } from '../utils/request'
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
  } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
  
  export default {
    name: 'DoughnutChart',
    components: {
      Doughnut
    },
    props: {
      chartId: {
        type: String,
        default: 'doughnut-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        label: [],
        labeldata:[],
        topics: [],
        chartData: {
          labels: [],
          tooltipText: ["test1", "test2", "test3"],
          datasets: [
            {
              backgroundColor: ['#41B883', '#E46651', '#00D8FF'],
              data: [] 
            }
          ]
        },
        chartOptions: {         
          responsive: true,
          maintainAspectRatio: false,
          plugins:{
            title:{
              display: true, 
              text:"The three most popular SDG topics",
              font:{
                size:20,
                family: " system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
              }
            },
            datalabels:{
                    display: false
                  },
            tooltip: {
              callbacks: {
                title: (context)=>{
                  return context[0].label}
                ,
                label: (context)=>{
                  return " " + this.labeldata[context.dataIndex]+ ": " + context.parsed}
                }
            },  
          },           
                    
          },
        // chart:{},
        
        nb:[]
      }
    },
    
    methods: {
      getChartNb(){
            // const path = `${this.GLOBAL.BASE_URL}/chart`;
            // axios.get(path)
            get('chart', null, false)
            .then((res) => {
                // console.log(res.data);
                this.getsdg(res.data);
                // console.log(this.chart);

            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        calcultotal(dicnb){
            // console.log(dicnb);
            var count={
                'SDG-1':0,
                'SDG-2':0,
                'SDG-3':0,
                'SDG-4':0,
                'SDG-5':0,
                'SDG-6':0,
                'SDG-7':0,
                'SDG-8':0,
                'SDG-9':0,
                'SDG-10':0,
                'SDG-11':0,
                'SDG-12':0,
                'SDG-13':0,
                'SDG-14':0,
                'SDG-15':0,
                'SDG-16':0
            }
            for(var ii in dicnb){
                
                for(var s=0; s< dicnb[ii]["pubs"].length;s++){
                    // console.log(dicnb[i]["pubs"][s])
                    for(var jj in count){
                        if(jj==dicnb[ii]["pubs"][s]["_id"]){
                            count[jj]+=dicnb[ii]["pubs"][s]["counts"];
                        }
                    }
                }
            }
            
            // this.chart = count;
            for(var i1 in count){
              this.label.push(i1);
              this.nb.push(count[i1]);
            }
            for(var i=0;i<this.nb.length;i++){
              for(var j=0;j<this.nb.length;j++){
                if(this.nb[j]<this.nb[j+1]){
                  var tenb=this.nb[j];
                  var tela=this.label[j];
                  this.nb[j]=this.nb[j+1];
                  this.label[j]=this.label[j+1];
                  this.nb[j+1]=tenb;
                  this.label[j+1]=tela;
                }
              }
            }
            this.chartData.datasets[0].data=this.nb.slice(0,3);
            this.chartData.labels=this.label.slice(0,3);
            // console.log(this.chartData.labels);
            // this.getsdg();
            // console.log(this.topics);
            var label = []
            for(var l=0;l<this.chartData.labels.length;l++) {
              // console.log(this.chartData.labels[l]);
              for(var sd=0;sd<this.topics.length;sd++) {
                if(this.topics[sd]["topic"]==this.chartData.labels[l]){
                  label[l] = this.topics[sd]["topicshortname"]
                }
              }
            }
            this.labeldata = label
        },
        getsdg(dics){
          // const path = `${this.GLOBAL.BASE_URL}`;
          // axios.get(path)
          get('keywords', null, false)
          .then((res) => {
              // console.log(res.data);
              // this.topics = res.data;

              for(var i = 0; i<res.data.length; i++){
                // console.log(res.data[i]['topic'])
                if(res.data[i]['topic'].indexOf("SDG") != -1){
                  this.topics.push(res.data[i])
                }            
              }
              // console.log(this.topics)
              this.calcultotal(dics);
          })
          .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          });
      }
    },
    created() {
      this.getChartNb();
    }
  }
  </script>
  