<template>
  <div>
  <div class="about_container">

    <h2 class="about_subtitle">Objective</h2>
    <p class="about_text">Our objective is to show and share data resources on Engage topics of societal change. We collect data of the repositories and existing data centers of our partner universities. Sharing and linking data resources will foster open science strategies in research.</p>
    
    <h2 class="about_subtitle">OAI-PMH</h2>
    <p class="about_text">We use the protocol <a class="about_link" href="http://www.openarchives.org/pmh/">OAI-PMH(Open Archives Initiative Protocol for Metadata Harvesting)</a> to harvest metadata from the repositories of our partners. We don't store the actual publications, we just get the metadata and store the links back to their own repositories. We collected publication data for each partner from 2015 onwards. We update the data regularly.We have now collected data from 7 partner universities and are in contact with Ramon Llull University and Hanken School of Economics. We expect to include their data in next releases.</p>
    
    <h2 class="about_subtitle">Topic</h2>
    <p class="about_text">This site shows only English-language publications related to ENGAGE topics (SDGs, digitalisation etc).</p>
    We use the following dataset to index publications in accordance with the SDG topics and Engage topics: <br>
    <a class="about_link" href="https://zenodo.org/record/4118028#.ZAc9nnbMLb0">Duran-Silva, Nicolau, Fuster, Enric, Massucci, Francesco Alessandro, & Quinquillà, Arnau. (2019). A controlled vocabulary defining the semantic perimeter of Sustainable Development Goals (1.3) [Data set]. Zenodo.</a>
    <br><br>Each topic will have a set of keywords, and if the keywords appear on the title, introduction, etc., we consider that the publication belongs to that topic.
    We update our database once a month.
    <h2 class="about_subtitle">Data Accuracy</h2>
    <p class="about_text">
      Our website aims to provide comprehensive information, particularly in the Collaboration section, where publications from multiple university databases are displayed. It's important to note that due to variations in how each university stores titles and author names, there may be discrepancies in the data. To address this, we employ a similarity-based approach to match publications. We strive to achieve a balance between accuracy and inclusivity, prioritizing similarity in titles and author names rather than strict character-by-character matching.
    </p>
    <p class="about_text">Additionally, our database includes publications in various languages. However, our search functionality primarily supports Latin-based characters. While we endeavor to include publications in all languages, please note that searches conducted in English may yield more accurate results.
    </p>
        <p class="about_text">Please be aware that while we strive for accuracy, we cannot guarantee the complete precision of the results due to the aforementioned factors.
    </p>
    <h2 class="about_subtitle">Projects</h2>
    <p class="about_text">In the Projects section, we feature projects sourced from the OpenAIRE database, which collates projects from various universities. We then cross-reference these projects with related publications in our database. It's important to note that the number of projects displayed may vary from the actual number of projects each university participates in. Our selection process ensures that only projects with associated publications available in our database are included. This approach is designed to align our displayed results with the scope of the ENGAGE project, providing users with relevant and curated content.</p>
    <h2 class="about_subtitle">Feedback</h2>
    <Feedback/>

  </div>
  </div>
</template>
<script>

import Feedback from '../components/Feedback.vue'

export default {
  components: { Feedback},

}
</script>

<style>

</style>