<template>
  <div class="graph_container">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
  </template>
  
  <script>
  import { Bar} from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  // import axios from 'axios';
import { get } from '../utils/request'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'BarChart',
    components: { Bar },
    props: {
      chartId: {
        type: String,
        default: 'Nb of recent topics'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 100
      },
      height: {
        type: Number,
        default: 0
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        chartData: {
          labels: [],
          
          datasets: [ { 
            label: "NUMBER OF PUBLICATIONS",
            data: [] ,
            backgroundColor: ['#41B883', '#E46651', '#00D8FF']}
           ]
        },
        chartOptions: {
          responsive: true,
          plugins:{
            legend: {
              display: false //Hide title label of datasets
            },
            title:{
              display: true, 
              text:"The three most popular SDG topics",
              font:{
                size:20,
                family: " system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
              }
            },
            datalabels:{
              color: '#fff',
          }                        
          },
          scales:{
            x:{
              grid:{
                display:false
              }
            },

            y:{
              grid:{
                display:false
              }
            }
            
          }
            
          },
          label:[],
          nb:[]
        }
      },
    
    methods: {
      getChartNb(){
            // const path = `${this.GLOBAL.BASE_URL}/chart`;
            // axios.get(path)
            get('chart', null, false)
            .then((res) => {
                // console.log(res.data);
                this.calcultotal(res.data);
                // console.log(this.chart);

            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            });
        },
        calcultotal(dicnb){
            // console.log(dicnb);
            var count={
                'SDG-1':0,
                'SDG-2':0,
                'SDG-3':0,
                'SDG-4':0,
                'SDG-5':0,
                'SDG-6':0,
                'SDG-7':0,
                'SDG-8':0,
                'SDG-9':0,
                'SDG-10':0,
                'SDG-11':0,
                'SDG-12':0,
                'SDG-13':0,
                'SDG-14':0,
                'SDG-15':0,
                'SDG-16':0
            }
            for(var ii in dicnb){
                // ii partner
                for(var s=0; s< dicnb[ii]["pubs"].length;s++){
                    // console.log(dicnb[i]["pubs"][s])
                    for(var jj in count){
                      // s sdg
                        if(jj==dicnb[ii]["pubs"][s]["_id"]){
                            count[jj]+=dicnb[ii]["pubs"][s]["counts"];
                        }
                    }
                }
            }
            
            // this.chart = count;
            for(var i1 in count){
              this.label.push(i1);
              this.nb.push(count[i1]);
            }
            for(var i=0;i<this.nb.length;i++){
              for(var j=0;j<this.nb.length;j++){
                if(this.nb[j]<this.nb[j+1]){
                  var tenb=this.nb[j];
                  var tela=this.label[j];
                  this.nb[j]=this.nb[j+1];
                  this.label[j]=this.label[j+1];
                  this.nb[j+1]=tenb;
                  this.label[j+1]=tela;
                }
              }
            }
            this.chartData.datasets[0].data=this.nb.slice(0,3);
            this.chartData.labels=this.label.slice(0,3);
        }
    },
    created() {
      this.getChartNb();
    }
    }
  
  </script>