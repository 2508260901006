import { get , post } from './utils/request'

export default {
    state: {
        partnerInfo: [],
    }, 
    setSharedVariable() {
        //get partners' information
        let path = `partnerinfo`; 
        get(path, null, false)
        .then((res) => {
            if(res.status==200){
                if(res.data.length!=0){
                    this.state.partnerInfo = res.data
                    }
        }})
        .catch((error) => {
          console.error(error);})
    },
    getSharedVariable() {
        // return partners' information
        return this.state.partnerInfo;
    },
    getNameAffiche(shortName){
        // return the corresponding abbreviation based on university ID
        if(this.state.partnerInfo.length > 0){
            for (var i = 0; i<this.state.partnerInfo.length; i++){
                if(this.state.partnerInfo[i].shortName == shortName){
                    return this.state.partnerInfo[i].NameAffiche
                }        
            }
        }
        return 'General';
    },
    getFullName(shortName){
        // return the corresponding full name based on university ID
        if(this.state.partnerInfo.length > 0){
            for (var i = 0; i<this.state.partnerInfo.length; i++){
                if(this.state.partnerInfo[i].shortName == shortName){
                    return this.state.partnerInfo[i].longName
                }        
            }
        } 
    },
    getShortName(NameAffiche){
        // return the corresponding ID based on university's abbreviation
        if(this.state.partnerInfo.length > 0){
            for (var i = 0; i<this.state.partnerInfo.length; i++){
                if(this.state.partnerInfo[i].NameAffiche == NameAffiche){
                    return this.state.partnerInfo[i].shortName
                }        
            }
        } 
    },
    getUniversitySorted(uninonsorted) {
        let sortedUniversity=[]
        for(var uni=0;uni<this.state.partnerInfo.length;uni++){
            sortedUniversity.push(this.state.partnerInfo[uni]['shortName'])
        }
        return sortedUniversity.filter(item => uninonsorted.includes(item));
        // let path = `partnerinfo`; 
        // get(path, null, false)
        // .then((res) => {
        //     if(res.status==200){
        //         if(res.data.length!=0){
        //             for(var uni=0;uni<res.data.length;uni++){
        //                 this.sortedUniversity.push(res.data[uni]['shortName'])
        //             }
                    // return this.sortedUniversity.filter(item => uninonsorted.includes(item));
                    // console.log(this.sortedUniversity)
                    // this.sortedUniversity = res.data
        //             }
        // }})
        // .catch((error) => {
        // console.error(error);})
    }

}