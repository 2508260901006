<template>
  
  <div style="height: 100%">
    <banner/>
    <router-view></router-view>
    <pagefooter/>
  </div>
</template>

<script>
import Banner from './components/Banner'
import pagefooter from './components/pagefooter'

export default {
    name:'App',
    components:{
      pagefooter,
      Banner
    },
}
</script>