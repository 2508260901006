<template>
    <div class="pagination"> 

        <div class="allpages"> 
            <input type="button" value="prev" @click="prePage">
            <span class="current-page"> 
                page : {{currentPage+1}} 
            </span>
            <input type="button" value="next" @click="nextPage">
            <br>
            <br>
            <input type="text" id="inputpagenb" :value=this.currentPage+1> <span>/{{pageNum}}</span>
            <input type="button" value="go" @click="verspage"> 
        </div>
    
    </div>
  </template>
  
  <script>
  export default {
    props: {  
        data: {
            type : Array,
            required: true
        },
        display: {
            type: Number,
            required: true
        },
    },
    data(){
        return {
            dataShow:[],  //data of this page
            total: 0,     // nb pubs total
            totalPage:[],  //data of each page
            currentPage: 0,   // current page, page1 default
            pageNum:1,    //nb pages total
        }
    },
    methods:{
        getPages(){
            this.total=this.data.length
            if(this.total.length == 0){
                this.$emit('returnData', [])
            }else{
                this.pageNum= Math.ceil(this.total / this.display) ||1
                for (let i = 0; i < this.pageNum; i++) {
                    this.totalPage[i] = this.data.slice(this.display * i, this.display * (i + 1))
                }
                // show data of the first page
                this.currentPage = 0
                this.dataShow = this.totalPage[this.currentPage];
                this.$emit('returnData', this.dataShow)
            }  
        },
        // previous
        prePage() {
            if (this.currentPage !== 0) {
                this.currentPage = this.currentPage - 1;
                this.dataShow = this.totalPage[this.currentPage];
                this.$emit('returnData', this.dataShow)
            }
        },
        // next
        nextPage() {
            if (this.currentPage !== this.pageNum - 1) {
                this.currentPage = this.currentPage + 1;
                this.dataShow = this.totalPage[this.currentPage];
                this.$emit('returnData', this.dataShow)
              }
            },
        
        // jump to nb page by number
        verspage(){
            var i=document.getElementById("inputpagenb").value;
            if(i>0 && i<=this.pageNum){
                this.currentPage = i-1
                this.dataShow = this.totalPage[this.currentPage];
                this.$emit('returnData', this.dataShow)
            }
            else{
                alert("Please enter a valid number ! ");
            }
            
        },
    },
    watch: {
        data() {
            this.getPages();
        }
    },
    created(){
        this.getPages();
    }
  };
  </script>