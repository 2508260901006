<template>
    <div class="body_container">

        <LoadingMessage 
            :messageDisplay=messageDisplay 
            :messageStatus=messageStatus 
        />

        <div v-if="!messageDisplay" class="publication-body">
            <div class="update-date-div"> 
            <p class="update-date"></p> 
            </div>

            <div v-for="(value,nam) in partnerPubs" :key="nam" >
                <div class="publication-element" v-if="value.length!=0">
                    <div class="publication-title">
                        <h2 class="publication-title-text"><router-link :to="`/onePartner?name=${nam}`" >{{getaffichename(nam)}}</router-link></h2>
                    </div>  
                    <div class="publication-main" v-for="p in value" :key="p.title">      
                        <ul>
                            <li>
                                <Pub
                                    :p = p
                                    :partner=true />
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            
        </div>
    </div>

</template>

<script>
// import axios from 'axios';
import Pub from '../components/Pub'
import { get } from '../utils/request'
import LoadingMessage from '../components/LoadingMessage'
import store from '../store';

export default {
    components:{
        LoadingMessage,
        Pub
    },
    data(){
        return {
            msg:'',
            partnerPubs:{},
            creators:[],
            partner:[],
            link:'',
            messageDisplay: false,
            messageStatus: "loading",
        }
    },
    methods:{
        getMessage() {
            this.messageDisplay = true;
            this.messageStatus = "loading";

            get(`partners`, null, true)
            .then((res) => {
                if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    this.partnerPubs = res.data;
                    if(this.partnerPubs.length == 0){
                        this.messageStatus = "no";
                    }else{
                        this.messageDisplay = false
                    }
                }
            })
            .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
            this.messageStatus = "service error";
            });
        },
        type(creator){
            if(Array.isArray(creator)){
                return false;
            }else{
                return true;
            }
        },
        linkPerson(name){
            this.link= store.linkPerson(name);
            console.log(this.link);
            return this.link;
        },
        getaffichename(un){
            return store.getNameAffiche(un);
        }

    },
    created() {
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        }  
        
        this.getMessage();
        this.getpartnerdata(); //function at main.js
    }

}
    
    


</script>

<style>

</style>