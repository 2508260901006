<template>
  
    <div id="feedbackbox">
        <p>Send us your feedback</p>

        <div class="feedback_form"> 
        
            <table class="feedback_table"> 
                <tr class="feedback_tr"> <td class="feedback_td"> Name :</td> <td class="feedback_td"><input type="text" v-model="emailcontent.name" required></td></tr>
                <tr class="feedback_tr"> <td class="feedback_td"> Email :</td> <td class="feedback_td"><input type="text" v-model="emailcontent.email" required></td></tr>
                <tr class="feedback_tr"> <td class="feedback_td"> Message :</td> <td class="feedback_td"><textarea rows="4" cols="50" v-model="emailcontent.message" class="feedback_text" required></textarea></td></tr>
            </table>
              You can also write to <a href="mailto:engage-cellule@ut-capitole.fr">engage-cellule@ut-capitole.fr</a> to send your feedback.
             
    
        </div>

        <button id="submit_btn" @click="submitForm">submit</button>

    </div>
</template>

<script>
import axios from 'axios';
import { post } from '../utils/request'

export default {
    data(){
        return{
           emailcontent:{
            name:'',
            message:''
           } 
        }
        
    },
    methods:
    {
        submitForm(){
            if(this.emailcontent.message!=""){
               // console.log(this.emailcontent)
            // const path = `${this.GLOBAL.BASE_URL}mail`
            post("mail", this.emailcontent, {headers:{"Content-Type" : "application/json"}}, false)
            .then((res) => {
                // console.log(res.data);
                if(res.data=='Sent'){
                    // console.log(res);
                    this.emailcontent="";
                    alert("Message sent! Thank you for your feedback ! ");
                }
            })
            .catch((error)=>{
                console.log(error)
            }) 
            }
            else{
                alert("Complete your message ! ")
            }
        }
    }
}
</script>

<style>


</style>