import Vue from 'vue'
import App from './App.vue'
import './assets/index.css'
import VueRouter from 'vue-router'
import router from './router'
import 'animate.css'


import 'element-ui/lib/theme-chalk/index.css'
import store from './store';

Vue.config.productionTip = false
Vue.use(VueRouter)

new Vue({
  el:'#app',
  render: h => h(App),
  data: {
    store: store.state
  },
  router: router
})
