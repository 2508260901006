<template>
    <div class="body_container">
    <div class="col_body">

        <!-- title -->
        <p class="col_des">
            Publications co-authored by our partners
        </p>
        <p class="col_des">
            To find more collaboration, please visit <a href="https://www.research-community-engage.eu/">Engage Community platform</a>
        </p>


        <div class=" collab_container resources_container">

        <!-- filter -->
        <Filters 
            :filter="filter"
            :total="total"
            @filterChange="getResult"
        />

        <!-- main content -->
        <div  class="resources_right">
            <LoadingMessage 
                :messageDisplay=messageDisplay 
                :messageStatus=messageStatus 
            /> 
            <div v-if="!messageDisplay && partnerReady">
                
                <div class="publication-element" v-for="p in dataShow" :key="p.index">  
                    <img v-if="engageLogo(p.topic)" class="collaboration_logo_engage" src="../assets/ENGAGE EU Logo.jpg"> <!---->
                        <ul >
                            <li>
                                <Pub
                                :p = p
                                :partner = true
                                />    
                                <div >
                                    <table>
                                        <tr>
                                            <td class="collaboration_partner_td"  v-for="partner in p.partners" :key="partner.partnerShortName">
                                                <div class="collaboration_partner_divglob">
                                                    <div class="collaboration_partner_div">
                                                        <img v-if="partners.length>0" class="collaboration_partnerLogo" :src="srcImg(partner.partnerShortName)" alt="Partner">
                                                    </div>
                                                    <div>
                                                        <a :href=partner.identifier target="_blank"><p class="publication-partners" v-if="showLink(partner.identifier)">click here for more information</p></a>
                                                    </div>
                                                </div>
                                            </td>
                                            
                                        </tr>
                                    </table>
                                </div>    
                            </li>
                        </ul>    
                </div>

                <pagination
                    :data = collaborationPubs
                    :display=display
                    @returnData="showPage" 
                />

            </div>
        </div>      
        </div>       
    </div>
    </div>
</template>
<style>

</style>
<script>
// import axios from 'axios';
import store from '../store';
import Pagination from '../components/pagination'
import Filters from '../components/Filters'
import LoadingMessage from '../components/LoadingMessage'
import { get } from '../utils/request'
import Pub from '../components/Pub.vue';

export default {
    name: 'Collaboration',
    components:{
        Filters,
        Pagination,
        LoadingMessage,
        Pub
    },
    data(){
        return {
            allcollab:[],
            collaborationPubs:[],
            partners:[],
            creators:[],

            total: 0,     // nb pubs total
            display: 10,   // nb max in one page
            dataShow:[],  //data of this page

            filter:{},
            search:{},

            msg:'',
            messageDisplay: true,
            partnerReady: false,
            messageStatus: "loading",
        }
    },
    methods:{
        getMessage() {
            this.messageDisplay = true;
            this.messageStatus = "loading";
            // const path = `${this.GLOBAL.BASE_URL}collaboration`;
            // axios.get(path)
            get("collaboration",null,true)
            .then((res) => {
                if(res.status!=200){
                    this.messageStatus = "network error";
                }else{
                    this.allcollab=res.data;
                    this.collaborationPubs = this.allcollab;
                    // generate filters 
                    this.getFilter();
                    this.total=this.collaborationPubs.length

                    if(this.total==0){
                        this.messageStatus = "no result"
                    }else{
                        this.messageDisplay = false;
                    }
            }})
            .catch((error) => {
                this.messageStatus = "service error";
                console.error(error);
            });
        },
        getFilter(){
            var filter = {
                    university:[],
                    topic:[]
            }

            for (var i = 0; i<this.allcollab.length; i++){
                this.allcollab[i].university = []
                for (var m = 0; m<this.allcollab[i].partners.length; m++){
                    if(!filter.university.includes(this.allcollab[i].partners[m].partnerShortName)){
                        filter.university.push(this.allcollab[i].partners[m].partnerShortName)
                    }
                    this.allcollab[i].university.push(this.allcollab[i].partners[m].partnerShortName)
                }
            
                if(typeof(this.allcollab[i].topic) == 'string'){
                    if(filter['topic'].indexOf(this.allcollab[i]['topic']) == -1){
                        filter['topic'].push(this.allcollab[i]['topic']);
                    }  
                }else{
                    for(var t=0;t<this.allcollab[i]['topic'].length;t++){
                        if(filter['topic'].indexOf(this.allcollab[i]['topic'][t]) == -1){
                            filter['topic'].push(this.allcollab[i]['topic'][t]);
                        }
                    }
                }
            }
            this.filter = filter;
        },

        showPage(returnData){
            if(returnData.length == 0){
                this.messageDisplay = true; 
                this.messageStatus = "no result"; 
            }else{
                this.$set(this, "dataShow", returnData)
                this.messageDisplay = false;  
                document.documentElement.scrollTop = 0;

            }
        },

        getResult(searchEl){
            this.search = searchEl
            if (Object.keys(this.search).length === 0){
                this.getMessage();
            }else{
                var result = this.allcollab
                for(var key in this.search){
                    result = this.filterList(key, result) 
                }
                this.collaborationPubs = [...new Set(result)]
            }
            this.total=this.collaborationPubs.length;
        },

        filterList(filter, list){
            if(this.search[filter].length>0){
                var result = []
                for(var i = 0; i<this.search[filter].length; i++){
                    var ruler = this.search[filter][i]
                    for(var j =0; j<list.length; j++){
                        if (list[j][filter].indexOf(ruler) != -1){
                            result.push(list[j])
                        }
                    }
                }
                return result
            }
            return list
        },

        type(el){
            if(Array.isArray(el)){
                return false;
            }else{
                return true;
            }
        },
        engageLogo(topic){
            if(topic!="other topic"){
                return true;
            }else{
                return false;
            }
        },
        showLink(link){
            if(link==""){
                return false;
            }else{
                return true;
            }
        },
        
        srcImg(shortName){
            //function for create src of logo
            //data:image/png;base64,{code base64 of image}
            //data:image/jpeg;base64,{code base64 of image}
            //data:image/x-icon;base64,{code base64 of image}
            for (var i = 0; i< this.partners.length; i++){
                if(this.partners[i]["shortName"] == shortName){
                    return "data:"+this.partners[i]["img"]["type"]+";base64,"+this.partners[i]["img"]["code"]
                    
                }
            }

            
        },
        getPartners(){
            setTimeout(() => {
            this.$set(this, 'partners', store.getSharedVariable());
            this.$set(this, 'partnerReady', true);
        }, 2000) 
        }
    },
    created() {
        if(store.getSharedVariable().length==0){
            store.setSharedVariable();
        }  
        this.getPartners();
        this.getMessage();
    },
   
}
</script>